import { StyledFlexCenterExpanded } from '@/components/styled';
import { Spinner } from '@/features/components/atoms/Spinner';
import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  src: string;
  width?: number;
  height?: number;
  padding?: number;
  hasBackground?: boolean;
  children?: React.ReactNode;
  mode?: 'beater' | 'spinner';
};

/**
 * JSDoc
 * @see 画像の読み込み中にスピナーを表示するコンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const ImageSpinner = memo(
  ({
    src,
    width = 24,
    height = 24,
    padding = 24,
    hasBackground,
    children,
    mode = 'spinner',
  }: Props) => {
    const [isLoading, setIsLoading] = useState(true);

    const loadImage = useCallback(() => {
      const image = new Image();
      image.src = src;
      image.onload = () => setIsLoading(false);
      image.onerror = () => setIsLoading(false);
    }, [src]);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
      loadImage();
    }, [src, loadImage]);

    return (
      <>
        {isLoading ? (
          <StyledFlexCenterExpanded>
            <Spinner
              hasBackground={hasBackground}
              width={width}
              height={height}
              padding={padding}
              mode={mode}
            />
          </StyledFlexCenterExpanded>
        ) : (
          <StyledFlexCenterExpanded>{children}</StyledFlexCenterExpanded>
        )}
      </>
    );
  },
);
