import { Box } from '@/components/atoms/Box';
import { Popper } from '@/components/molecules/Popper';
import {
  StyledFlexGap10,
  StyledFlexGap5,
  StyledFont16Bold,
  StyledFontBold,
  StyledFontSx,
  StyledGridGap10,
  StyledGridGap5,
} from '@/components/styled';
import { DOCUMENT_OF_DOWNLOAD_FORMAT } from '@/constants';
import {
  StyledFunctionPadding,
  StyledFunctionPartition,
} from '@/features/components/styled/function';
import { StyledGrayIconBox } from '@/features/components/styled/icon';
import { usePopper } from '@/hooks/local/usePopper';
import { Divider } from '@mui/material';
import Link from 'next/link';
import { memo } from 'react';
import { MdInfo } from 'react-icons/md';
import { TbExternalLink } from 'react-icons/tb';
import { BackgroundStatus } from './BackgroundStatus';
import { DownloadButton } from './DownloadButton';

type Props = {
  isDialog?: boolean;
  targetFeatureName: string;
  downloadingName: string;
  handleDownloadWithApi: (format: string) => Promise<void>;
  isNoBackground: boolean;
};

export const Functions = memo(
  ({
    isDialog,
    targetFeatureName,
    downloadingName,
    handleDownloadWithApi,
    isNoBackground,
  }: Props): JSX.Element => {
    const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
      usePopper();

    return (
      <>
        <StyledFunctionPadding sx={{ marginTop: isDialog ? '-32px' : '' }}>
          <StyledGridGap10>
            <StyledFlexGap10>
              <StyledFont16Bold>出力形式</StyledFont16Bold>

              <StyledGrayIconBox
                sx={{ width: 24 }}
                onMouseOver={handleOpenPopper}
                onClick={handleClosePopper}
              >
                <MdInfo size={24} />
              </StyledGrayIconBox>
            </StyledFlexGap10>
            <BackgroundStatus
              targetFeatureName={targetFeatureName}
              isNoBackground={isNoBackground}
            />

            <DownloadButton
              target="png"
              width={135}
              label="PNG"
              label2="高画質"
              downloadingName={downloadingName}
              handleDownloadWithApi={handleDownloadWithApi}
              isDialog={isDialog}
            />
            {!isNoBackground && (
              <DownloadButton
                target="jpg"
                width={135}
                label="JPG"
                label2="軽量・高圧縮"
                downloadingName={downloadingName}
                handleDownloadWithApi={handleDownloadWithApi}
                isDialog={isDialog}
              />
            )}
            <DownloadButton
              target="webp"
              width={135}
              label="WebP"
              label2="Web最適化"
              downloadingName={downloadingName}
              handleDownloadWithApi={handleDownloadWithApi}
              isDialog={isDialog}
            />
            {!isNoBackground && (
              <DownloadButton
                target="psd"
                width={135}
                label="PSD"
                label2="編集可能"
                downloadingName={downloadingName}
                handleDownloadWithApi={handleDownloadWithApi}
                isDialog={isDialog}
              />
            )}
            <StyledFunctionPartition />
          </StyledGridGap10>
        </StyledFunctionPadding>

        <Popper
          title=""
          isOpenPopper={isOpenPopper}
          onClose={handleClosePopper}
          anchorElement={anchorElement}
          placement="bottom-start"
          placementY={10}
          hasClose={false}
          boxShadow="0px 4px 5px -2px rgba(0, 0, 0, 0.20), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)"
          borderRadius="4px"
          paddingRight="0px"
        >
          <StyledGridGap10
            style={{ whiteSpace: 'nowrap', padding: '16px', fontSize: '14px' }}
          >
            <StyledGridGap5>
              <Box style={{ width: 80 }}>
                <StyledFontBold>PNG</StyledFontBold>
              </Box>
              無損失圧縮で高画質を維持・ファイルサイズ大
              <Divider style={{ padding: '3px 0px' }} />
              <Box style={{ height: '3px' }} />
              <Box style={{ width: 80 }}>
                <StyledFontBold>JPG</StyledFontBold>
              </Box>
              高い圧縮率で画質を多少犠牲に・ファイルサイズ小
              <Divider style={{ padding: '3px 0px' }} />
              <Box style={{ height: '3px' }} />
              <Box style={{ width: 80 }}>
                <StyledFontBold>WebP</StyledFontBold>
              </Box>
              PNGとJPGの中間・画質と圧縮率のバランスが良い
              <Divider style={{ padding: '3px 0px' }} />
              <Box style={{ height: '3px' }} />
              <Box style={{ width: 80 }}>
                <StyledFontBold>PSD</StyledFontBold>
              </Box>
              レイヤー情報を保持・ファイルサイズ非常に大
              <Divider style={{ padding: '3px 0px' }} />
              <Box style={{ height: '3px' }} />
              <Link
                href={DOCUMENT_OF_DOWNLOAD_FORMAT}
                target="_blank"
                style={{
                  color: 'var(--color-primary-deep)',
                  textDecoration: 'none',
                }}
              >
                <StyledFlexGap5>
                  <TbExternalLink size={24} />
                  <StyledFontSx sx={{ fontSize: '12px' }}>
                    テクニックブックを見る
                  </StyledFontSx>
                </StyledFlexGap5>
              </Link>
            </StyledGridGap5>
          </StyledGridGap10>
        </Popper>
      </>
    );
  },
);
