import { StyledBoxWidth10, StyledFlex } from '@/components/styled';
import { StyledContactTallyButton } from '@/components/styled/button';
import Script from 'next/script';
import { memo } from 'react';
import { MdInfo } from 'react-icons/md';

/**
 * JSDoc
 * @see コンタクトタリー
 * @returns {JSX.Element}
 */
export const ContactTally = memo((): JSX.Element => {
  return (
    <StyledFlex>
      <StyledBoxWidth10 />
      <Script async src="https://tally.so/widgets/embed.js" />

      <StyledContactTallyButton>
        <MdInfo color="var(--color-gray-white)" size={18} />
        お問い合わせ
      </StyledContactTallyButton>
    </StyledFlex>
  );
});
