import { Box } from '@/components/atoms/Box';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import Image from 'next/image';
import { memo } from 'react';
import { StyledSmallCircleContainer } from '../../styled';

export const KeepMaskCircle = memo((): JSX.Element => {
  const { featureData, activeFeatureName } = useFeaturesContext({});

  return (
    <>
      {featureData?.single?.param?.keepMaskImage && (
        <StyledSmallCircleContainer>
          <Box
            sx={{
              width: '33px',
              height: '33px',
              borderRadius: '50%',
              outline: '2px solid #fff',
            }}
          >
            <ImageSpinner src={featureData?.single?.param?.keepMaskImage}>
              <Image
                src={featureData?.single?.param?.keepMaskImage}
                fill
                alt="pict"
                quality={90}
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />
            </ImageSpinner>
          </Box>
        </StyledSmallCircleContainer>
      )}
    </>
  );
});
