import { Box } from '@/components/atoms/Box';
import { StyledFlexCenterHeightExpanded } from '@/components/styled';
import { FeatureData, ImageFields } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { Spinner } from '@/features/components/atoms/Spinner';
import { StyledLoadingSpinnerBox } from '@/features/components/styled/loading';
import { StyledPreviewImage } from '@/features/components/styled/preview';
import { memo } from 'react';
import { DisposePointPin } from './DisposePointPin';
import { useSegmentationAnything } from './hooks/useSegmentationAnything';
import { useSegmentationKeys } from './hooks/useSegmentationKeys';

type Props = {
  field: ImageFields;
  isKeepMask?: boolean;
  data: FeatureData;
  updateData: (field: keyof FeatureData, value: unknown) => void;
};

/**
 * JSDoc
 * @see セグメンテーションの画像を表示するコンポーネント
 * @see manualの場合はポイントを追加しピンを表示する
 * @param {Props} { field }
 * @returns {JSX.Element}
 */
export const GlobalSegmentation = memo(
  ({ field, isKeepMask = false, data, updateData }: Props): JSX.Element => {
    const { previewSize, handleClick } = useSegmentationAnything({
      field,
      isKeepMask,
      data,
      updateData,
    });

    const { positiveKey, negativeKey, isPreviewLoadingKey, combinedKey } =
      useSegmentationKeys(isKeepMask);

    return (
      <>
        {data?.[field].base64 && (
          <StyledFlexCenterHeightExpanded>
            <Box
              sx={{
                position: 'relative',
                width: previewSize?.width,
                height: previewSize?.height,
              }}
            >
              <DisposePointPin
                field={field}
                pointType="positive"
                data={data}
                updateData={updateData}
                points={data?.[field][positiveKey] || []}
                isKeepMask={isKeepMask}
              />
              <DisposePointPin
                field={field}
                pointType="negative"
                data={data}
                updateData={updateData}
                points={data?.[field][negativeKey] || []}
                isKeepMask={isKeepMask}
              />
              {data?.[field][isPreviewLoadingKey] && (
                <StyledLoadingSpinnerBox>
                  <Spinner />
                </StyledLoadingSpinnerBox>
              )}
              {!data?.[field][combinedKey] && (
                <ImageSpinner src={data?.[field].base64}>
                  <StyledPreviewImage
                    src={data?.[field].base64}
                    fill
                    alt="pict"
                    quality={90}
                    onClick={handleClick}
                  />
                </ImageSpinner>
              )}
              {data?.[field][combinedKey] && (
                <ImageSpinner src={data?.[field][combinedKey]}>
                  <StyledPreviewImage
                    src={data?.[field][combinedKey]}
                    fill
                    alt="pict"
                    quality={90}
                    onClick={handleClick}
                  />
                </ImageSpinner>
              )}
            </Box>
          </StyledFlexCenterHeightExpanded>
        )}
      </>
    );
  },
);
