import { StyledBoxWidth20 } from '@/components/styled';
import { memo } from 'react';
import { BreadcrumbTitle } from './BreadcrumbTitle';
import { ContactTally } from './ContactTally';
import { Poppers } from './Poppers';
import {
  StyledContentHeaderBox,
  StyledRightContainer,
  StyledRightContentsBox,
} from './styled';

/**
 * JSDoc
 * @see コンテンツヘッダー
 * @see パンくずリスト風のタイトルと右上のポッパー群、コンタクトタリーを表示する
 * @returns {JSX.Element}
 */
export const ContentHeader = memo((): JSX.Element => {
  return (
    <StyledContentHeaderBox>
      <BreadcrumbTitle />
      <StyledRightContainer>
        <StyledRightContentsBox>
          <Poppers />
          <StyledBoxWidth20 />
          <ContactTally />
        </StyledRightContentsBox>
      </StyledRightContainer>
    </StyledContentHeaderBox>
  );
});
