import { setContext } from '@sentry/nextjs';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

/**
 * JSDoc
 * @see axiosのGETリクエストを行う
 * @see 型ガードを介したくない場合はこちらを使用する
 * @see 新機能ではこちらを使用するのが無難
 * @see 機能が安定したらaxiosGetWithZodを使用する
 * @param apiName - APIのエンドポイント名
 * @param payload - リクエストボディ
 * @returns レスポンスデータ
 * @see camelcaseKeysを使用してキャメルケースに変換している
 */
export const axiosGet = async (apiName: string, payload?: unknown) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const snakedPayload =
    payload &&
    snakecaseKeys(payload as Record<string, unknown>, { deep: true });
  const queryString =
    payload &&
    Object.keys(snakedPayload as Record<string, string | number | boolean>)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            (snakedPayload as Record<string, string | number | boolean>)[key],
          )}`,
      )
      .join('&');

  const response = await axios.get(
    queryString
      ? `/api/backend/${apiName}?${queryString}`
      : `/api/backend/${apiName}`,
    {
      headers,
    },
  );

  // TODO: Biomeでは適用されていないルール。同様のものがないか探す
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = camelcaseKeys(response.data, { deep: true });

  // TODO: Biomeでは適用されていないルール。同様のものがないか探す
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
};

/**
 * JSDoc
 * @see axiosのGETリクエストを行う
 * @see Zodを用いて型ガードを行う場合はこちらを使用する
 * @param apiName - APIのエンドポイント名
 * @param schema - レスポンスデータの検証スキーマ
 * @param payload - リクエストボディ
 * @returns レスポンスデータ
 * @see camelcaseKeysを使用してキャメルケースに変換している
 */
export const axiosGetWithZod = async <T>(
  apiName: string,
  schema: z.ZodSchema<T>,
  payload?: unknown,
) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const snakedPayload =
    payload &&
    snakecaseKeys(payload as Record<string, unknown>, { deep: true });
  const queryString =
    payload &&
    Object.keys(snakedPayload as Record<string, string | number | boolean>)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            (snakedPayload as Record<string, string | number | boolean>)[key],
          )}`,
      )
      .join('&');

  try {
    const response = await axios.get(
      queryString
        ? `/api/backend/${apiName}?${queryString}`
        : `/api/backend/${apiName}`,
      {
        headers,
      },
    );
    // TODO: Biomeでは適用されていないルール。同様のものがないか探す
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = camelcaseKeys(response.data, { deep: true });
    // データ検証を試みる
    const data = schema.parse(parsedData);

    return data;
  } catch (err) {
    setContext('context', {
      apiName,
      payload,
    })

    if (err instanceof Error) {
      console.log(err);
    }
  }
};
