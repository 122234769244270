import { ActiveTriangle } from '@/components/icons';
import {
  StyledBoxRelativeWidthExpanded,
  StyledFlexCenter,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { memo } from 'react';
import {
  StyledBackgroundResultBox,
  StyledBackgroundResultContent,
  StyledSettingResultImage,
  StyledTrianglePosition,
  StyledTriangleWrapper,
} from '../../styled';

type ResultTriangleProps = {
  targetType: string | undefined;
};
const ResultTriangle = ({ targetType }: ResultTriangleProps) => {
  return (
    <StyledTriangleWrapper
      trianglePositionType={
        targetType === 'preset'
          ? 'rightOfCenter'
          : targetType === 'preset'
          ? 'right'
          : 'left'
      }
    >
      <StyledTrianglePosition>
        <ActiveTriangle />
      </StyledTrianglePosition>
    </StyledTriangleWrapper>
  );
};

type Props = {
  currentNumber: number;
  targetType: string;
  handleOpenBackgroundDialogWithLoading: (targetNumber: number) => void;
  handleOpenPresetDialog: () => void;
  backgroundUrl: string;
  presetUrl: string;
};
export const ResultBox = memo(
  ({
    currentNumber,
    targetType,
    handleOpenBackgroundDialogWithLoading,
    handleOpenPresetDialog,
    backgroundUrl,
    presetUrl,
  }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    return (
      <div>
        <ResultTriangle targetType={targetType} />
        <StyledBackgroundResultBox>
          {targetType === 'image' && (
            <StyledBoxRelativeWidthExpanded
              onClick={() => {
                handleOpenBackgroundDialogWithLoading(currentNumber);
              }}
            >
              <StyledFlexCenter>
                {backgroundUrl && (
                  <ImageSpinner
                    src={backgroundUrl}
                    mode="beater"
                    hasBackground={false}
                  >
                    <StyledSettingResultImage
                      hasPadding
                      src={backgroundUrl}
                      fill
                      alt="pict"
                    />
                  </ImageSpinner>
                )}
                {!backgroundUrl && (
                  <StyledBackgroundResultContent>
                    クリックして画像を選択
                  </StyledBackgroundResultContent>
                )}
              </StyledFlexCenter>
            </StyledBoxRelativeWidthExpanded>
          )}
          {targetType === 'preset' && (
            <StyledBoxRelativeWidthExpanded onClick={handleOpenPresetDialog}>
              <StyledFlexCenter>
                {presetUrl && (
                  <ImageSpinner
                    src={presetUrl}
                    mode="beater"
                    hasBackground={false}
                  >
                    <StyledSettingResultImage
                      hasPadding
                      src={presetUrl}
                      fill
                      alt="pict"
                    />
                  </ImageSpinner>
                )}
                {!presetUrl && (
                  <StyledBackgroundResultContent>
                    プリセットを選択
                  </StyledBackgroundResultContent>
                )}
              </StyledFlexCenter>
            </StyledBoxRelativeWidthExpanded>
          )}
        </StyledBackgroundResultBox>
      </div>
    );
  },
);
