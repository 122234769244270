import { ActiveTriangle } from '@/components/icons';
import {
  StyledBoxRelativeWidthExpanded,
  StyledFlexCenter,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { memo } from 'react';
import {
  StyledBackgroundResultBox,
  StyledBackgroundResultContent,
  StyledSettingResultImage,
  StyledTrianglePosition,
  StyledTriangleWrapper,
} from '../../styled';

type ResultTriangleProps = {
  targetType: string | undefined;
};
const ResultTriangle = ({ targetType }: ResultTriangleProps) => {
  return (
    <StyledTriangleWrapper
      trianglePositionType={
        targetType === 'preset'
          ? 'rightOfCenter'
          : targetType === 'preset'
          ? 'right'
          : 'left'
      }
    >
      <StyledTrianglePosition>
        <ActiveTriangle />
      </StyledTrianglePosition>
    </StyledTriangleWrapper>
  );
};

type Props = {
  handleOpenDialog: () => void;
  handleOpenPresetDialog: () => void;
};
export const ResultBox = memo(
  ({ handleOpenDialog, handleOpenPresetDialog }: Props): JSX.Element => {
    const { featureData } = useFeaturesContext({});

    return (
      <div>
        <ResultTriangle targetType={featureData?.single?.param?.targetType} />
        <StyledBackgroundResultBox>
          {featureData?.single?.param?.targetType === 'image' && (
            <StyledBoxRelativeWidthExpanded onClick={handleOpenDialog}>
              <StyledFlexCenter>
                {featureData?.single?.param.currentRef && (
                  <ImageSpinner
                    src={featureData?.single?.param.currentRef}
                    mode="beater"
                    hasBackground={false}
                  >
                    <StyledSettingResultImage
                      hasPadding
                      src={featureData?.single?.param.currentRef}
                      fill
                      alt="pict"
                    />
                  </ImageSpinner>
                )}
                {!featureData?.single?.param.currentRef && (
                  <StyledBackgroundResultContent>
                    クリックして画像を選択
                  </StyledBackgroundResultContent>
                )}
              </StyledFlexCenter>
            </StyledBoxRelativeWidthExpanded>
          )}
          {featureData?.single?.param?.targetType === 'preset' && (
            <StyledBoxRelativeWidthExpanded onClick={handleOpenPresetDialog}>
              {featureData?.single?.param?.presetUrl && (
                <ImageSpinner
                  src={featureData?.single?.param?.presetUrl}
                  mode="beater"
                  hasBackground={false}
                >
                  <StyledSettingResultImage
                    hasPadding
                    src={featureData?.single?.param?.presetUrl}
                    fill
                    alt="pict"
                  />
                </ImageSpinner>
              )}
              {!featureData?.single?.param?.presetUrl && (
                <StyledBackgroundResultContent>
                  プリセットを選択
                </StyledBackgroundResultContent>
              )}
            </StyledBoxRelativeWidthExpanded>
          )}
        </StyledBackgroundResultBox>
      </div>
    );
  },
);
