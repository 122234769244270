import { useBackendApi } from '@/api';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { CheckedCircle, UnCheckedCircle } from '@/components/icons';
import { StyledFlex } from '@/components/styled';
import { MAX_SUPPORT_MESSAGE_LENGTH } from '@/constants/support';
import { useAuthContext } from '@/contexts/AuthContext';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { DownloadDialogMode } from '@/features/components/modals/DownloadDialog';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useError } from '@/hooks/global/useError';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { UseQueryResult } from '@tanstack/react-query';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SupportForm } from '../SupportForm/SupportForm';
import { useSwiperState } from '../hooks/useSwiperState';
import {
  StyledImage,
  StyledThumbnail,
  StyledThumbnailBox,
  StyledThumbnailContainer,
} from './styled';

type Props = {
  imageResults?: ImageResult[];
  label?: React.ReactNode;
  hasCheckIcon?: boolean;
  thumbnailBoxWidth?: number;
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  swiperRef: any;
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
  variant: 'primary' | 'teal';
  downloadDialogMode?: DownloadDialogMode;
  taskId: string | undefined;
  isOpenSupportAccordion?: boolean;
  targetModeEn: string | undefined;
  handleCloseDownloadDialog: () => void;
  refetchTasks: UseQueryResult['refetch'];
};

export const SwiperThumbs = memo(
  ({
    imageResults,
    label,
    hasCheckIcon = false,
    thumbnailBoxWidth = 420,
    swiperRef,
    setSwiperRef,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
    variant,
    downloadDialogMode,
    taskId,
    isOpenSupportAccordion,
    targetModeEn,
    handleCloseDownloadDialog,
    refetchTasks,
  }: Props) => {
    const thumbnailBoxRef = useRef<HTMLDivElement>(null);
    const { handleChangeSlide } = useSwiperState({
      swiperRef,
      setSwiperRef,
      swiperIndex,
      setSwiperIndex,
    });

    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageResults?.[0]?.url,
      maxWidth: 72,
      maxHeight: 72,
    });

    const { checkIndex, findChecked, findDownloaded } = useCheckArray();

    // MEMO: サポート用の機能。既存構造的に分離できないので、いったんここにまとめる。時間のあるときにDownloadDialog自体をすべて作り直す
    const { logger } = useError();
    const { user } = useAuthContext()
    const { postInquiries } = useBackendApi({})
    const { checkedCount } = useCheckArray();
    const { handleOpenSnackbar, setSnackbarParam } = useSnackbar();
    const [message, setMessage] = useState('');
    const isDisplaySupportForm =downloadDialogMode === 'supportForm';
    const [isPostInquirying, setIsPostInquirying] = useState(false);
    const isSupportButtonDisabled = useMemo(() => {
      return message.length === 0 || message.length > MAX_SUPPORT_MESSAGE_LENGTH || checkedCount === 0 || isPostInquirying
    }, [
      message,
      checkedCount,
      isPostInquirying,
    ])
    const placeholder = useMemo(() => {
      switch (targetModeEn) {
        case 'background':
          return '生成した背景の一部に歪みが発生し、背景として成立していない。'
        case 'backgroundLora':
          return '生成した背景の一部に歪みが発生し、背景として成立していない。'
        case 'white':
          return 'モデルの髪の毛がぼやけてしまい、綺麗に白抜きができていない。'
        case 'multiWhite':
          return 'モデルの髪の毛がぼやけてしまい、綺麗に白抜きができていない。'
        case 'color':
          return '色がのっぺりとしてしまい、カラーの再現度が低い。'
        case 'item':
          return '着せ替えたかった指定アイテムが生成後に崩れ、意図したコーディネート画像が完成していない。'
        case 'colorTemperature':
          return '服以外にも色温度調整がかかっているに思われ、髪の毛にノイズのようなものが入っている。'
        default:
          return ''
      }
    }, [targetModeEn])
    const handlePostInquiry = useCallback(async () => {
      if (!user?.id || !message || !taskId || imageResults === undefined || imageResults.length === 0) {
        return
      }
      try {
        setIsPostInquirying(true)
        await postInquiries({
          userId: user.id,
          taskId,
          taskResultImageIds: imageResults.map((imageResult) => imageResult.id),
          message,
          inquiryType: 'bug',
        })

        refetchTasks();
        // MEMO: refetchに合わせたいので適当に遅らせる
        setTimeout(() => {
          handleCloseDownloadDialog();

          setSnackbarParam({
            iconName: 'success',
            text: `サポートを申請しました
            メールをご確認ください`,
            position: { placement: 'rightTop', x: 0, y: 0 },
          });
          handleOpenSnackbar();

          setIsPostInquirying(false)
        }, 1000);
      } catch (error) {
        logger({ error })
        handleCloseDownloadDialog();
      }
    }, [
      user?.id,
      message,
      taskId,
      imageResults,
      postInquiries,
      handleCloseDownloadDialog,
      handleOpenSnackbar,
      setSnackbarParam,
      refetchTasks,
      logger,
    ]);

    const displayImageResults = useMemo(() => {
      if (imageResults === undefined) return []

      return isDisplaySupportForm && !isOpenSupportAccordion ? imageResults.slice(0,4) : imageResults
    }, [
      imageResults,
      isDisplaySupportForm,
      isOpenSupportAccordion,
    ])



    return (
      <Box
        style={{
          position: 'relative',
          height: 'auto',
          overflow: 'auto',
          overflowX: 'hidden',
          paddingTop: '2px',
        }}
      >
        <StyledFlex style={{width: '100%'}}>
          <StyledThumbnailContainer
            ref={thumbnailBoxRef}
          >
            {label && <>{label}</>}
            <div
              style={{
                overflow: `${isDisplaySupportForm ? 'hidden auto' : 'inherit'}`,
                height: `${isDisplaySupportForm ? '441px' : 'auto'}`,
              }}
            >
              <StyledThumbnailBox>
                {displayImageResults &&
                  displayImageResults.length > 0 &&
                  displayImageResults.map((imageResult: ImageResult, i: number) => (
                    <StyledThumbnail
                      key={`thumb-${imageResult.url}`.toString()}
                      index={i}
                      swiperIndex={swiperIndex}
                      hasCheckIcon={hasCheckIcon}
                      isChecked={findChecked(i) || false}
                      variant={variant}
                      onClick={() => {
                        handleChangeSlide(i);
                      }}
                    >
                      {hasCheckIcon && (
                        <Box
                          style={{
                            position: 'absolute',
                            zIndex: 11,
                            marginLeft: 47,
                            marginTop: 47,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            checkIndex(i);
                          }}
                        >
                          {findChecked(i) ? (
                            <CheckedCircle
                              color={variant === 'teal' ? 'var(--color-teal-400)' : undefined}
                            />
                          ) : (
                            <UnCheckedCircle />
                          )}
                        </Box>
                      )}
                      {findDownloaded(i) && (
                        <Box
                          style={{
                            position: 'absolute',
                            zIndex: 10,
                            marginLeft: -60,
                            marginTop: -60,
                          }}
                        >
                          <Box
                            style={{
                              width: 8,
                              height: 8,
                              backgroundColor: '#545454',
                              border: '1px solid #fff',
                              borderRadius: '50%',
                            }}
                          />
                        </Box>
                      )}
                      {imageResult.url && (
                        <ImageSpinner
                          width={18}
                          height={18}
                          padding={0}
                          hasBackground={false}
                          src={`${imageResult.url}&d=62x72`}
                        >
                          <StyledImage
                            src={`${imageResult.url}&d=62x72`}
                            alt={`pict${i}`}
                            width={imageWidth}
                            height={imageHeight}
                            style={{
                              objectFit: 'contain',
                              backgroundImage:
                                repeatBackgroundImage && imageWidth && imageHeight
                                  ? `url(${repeatBackgroundImage})`
                                  : '',
                            }}
                          />
                        </ImageSpinner>
                      )}
                    </StyledThumbnail>
                  ))}
              </StyledThumbnailBox>
              { isDisplaySupportForm &&
              <div style={{marginTop: '32px'}}>
                <SupportForm
                  message={message}
                  setMessage={setMessage}
                  placeholder={placeholder}
                />
              </div>
              }
            </div>
          </StyledThumbnailContainer>
        </StyledFlex>
        { isDisplaySupportForm &&
          <div style={{
            position: 'relative',
            zIndex: 5,
            paddingLeft: '16px',
            marginTop: `${(isDisplaySupportForm && isOpenSupportAccordion) ? '34px' : '34px'}`,
          }}>
            <Button
              style={{ width: 360, fontSize: 15}}
              onClick={handlePostInquiry}
              disabled={isSupportButtonDisabled}
              mode='teal'
            >
              {isPostInquirying ? (
                <ClipLoader
                  size={24}
                  color="var(--color-gray-soft)"
                  speedMultiplier={0.5}
                />
              ) : 'サポートを申請'}
            </Button>
          </div>
        }

      </Box>
    );
  },
);
