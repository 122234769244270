import { useReactQuery } from '@/api/hooks/useReactQuery';
import { captureException, setContext } from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { z } from 'zod';

/**
 * JSDoc
 * @see useReactQueryとZod併用してGETリクエストと型ガードを行うカスタムフック
 * @see 型ガードを介したくない場合はuseReactQueryのuseGetを使用する
 * @param apiName - APIのエンドポイント名
 * @param schema - レスポンスデータの検証スキーマ
 * @param payload - リクエストボディ
 * @param timer - キャッシュの有効期限
 * @returns レスポンスデータ
 */
export const useZodGet = <T>(
  apiName: string,
  schema: z.ZodSchema<T>,
  payload?: unknown,
  timer?: number,
) => {
  const { useGet } = useReactQuery();
  const {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGet(
    apiName,
    payload,
    timer,
  );

  const [validatedData, setValidatedData] = useState<T | undefined>(undefined);
  const [validationError, setValidationError] = useState<Error | undefined>(
    undefined,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: sentryのための変数を依存配列に入れると `Maximum update depth exceeded.` のwarningが出るので一時的に無視。後ほど対応する
  useEffect(() => {
    if (isLoading || error) {
      setValidatedData(undefined);
      setValidationError(undefined);

      return;
    }
    try {
      const parsedData = schema.parse(data);
      setValidatedData(parsedData);
      setValidationError(undefined);
    } catch (err) {
      if (err instanceof Error) {
        // TODO: Sentryを8.xにアップデートしてcaptureConsoleIntegrationを使うようにする
        setContext('context', {
          apiName,
          payload,
          json: JSON.stringify(data),
        })
        console.error(err);
        captureException(err)
        setValidationError(err);
      }
    }
  }, [data, isLoading, error, schema]);

  return {
    data: validatedData,
    isLoading,
    isFetching,
    error: validationError || error,
    refetch,
  };
};
