import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { CheckedCircle, UnCheckedCircle } from '@/components/icons';
import { StyledFlex, StyledFlexGap5 } from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { FeatureBatch } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { useBase64 } from '@/hooks/utils/useBase64';
import { memo, useCallback, useState } from 'react';
import { MdOutlineLoop } from 'react-icons/md';
import { CombinedSilhouetteImage } from './CombinedSilhouetteImage';
import {
  StyledImageSetThumbnailBox,
  StyledImageSetThumbnailCenter,
} from './styled';

type Props = {
  set: FeatureBatch;
  apiGetResponse: Task | undefined;
  setRetryId: (retryId: string) => void;
  batchId: string;
  setTaskApiResponse: (task: Task | undefined) => void;
  setIsOpenAccordion: (isOpenAccordion: boolean) => void;
  isSelectedColumn: boolean;
};
export const BackgroundLoraContent = memo(
  ({
    set,
    apiGetResponse,
    setRetryId,
    batchId,
    setTaskApiResponse,
    setIsOpenAccordion,
    isSelectedColumn,
  }: Props): JSX.Element => {
    const { featureData, updateFeatureDataArray } = useFeaturesContext({});
    const [selectedNumber, setSelectedNumber] = useState(-1);
    const { postImages } = useBackendApi({});
    const { getImageBase64 } = useBase64();

    const handleReGenerate = useCallback((setId: string) => {
      // MEMO: なぜset.idではなくてbatchIdを渡しているのか不明
      setRetryId(batchId);
      setTaskApiResponse(apiGetResponse);
      setIsOpenAccordion(false);
      // 選択済みバリエーション情報のリセット
      const updateParams = featureData?.batch?.map((batch) => {
        if (batch.id === setId) {
          return {
            ...batch,
            refImage: {
              ...batch.refImage,
              refImageSelectedId: '',
              refImageSelectedUrl: '',
            },
          };
        }
        return batch;
      });
      updateFeatureDataArray('batch', updateParams);
    }, [
      batchId,
      setRetryId,
      setTaskApiResponse,
      apiGetResponse,
      setIsOpenAccordion,
      featureData,
      updateFeatureDataArray,
    ]);

    const handleSelectRefImage = useCallback(
      async (index: number, url: string) => {
        if (!featureData) return;
        setSelectedNumber(index);
        const base64 = await getImageBase64(url);
        try {
          const res = await postImages({
            image: base64,
            fileName: 'ref',
          });
          console.log(res);
          if (res) {
            const allUpdate = featureData.batch?.map((batch) => {
              if (batch.id === set.id) {
                return {
                  ...batch,
                  refImage: {
                    ...batch.refImage,
                    refImageSelectedId: res.id,
                    refImageSelectedUrl: res.originalImageUrl,
                  },
                };
              }

              return batch;
            });

            updateFeatureDataArray('batch', allUpdate);
          }
        } catch (error) {
          console.error(error);
        }
      },
      [featureData, getImageBase64, postImages, set, updateFeatureDataArray],
    );

    return (
      <>
        <Box
          style={{
            borderTop: '1px solid #ddd',
            display: 'grid',
            padding: '16px 24px 16px 24px',
          }}
        >
          <Box style={{ display: 'grid', gap: '16px' }}>
            <Box>
              <Button
                mode="border"
                width={180}
                height={30}
                style={{ fontSize: 13 }}
                onClick={() => handleReGenerate(set.id)}
              >
                <StyledFlexGap5>
                  <MdOutlineLoop />
                  バリエーションを再生成
                </StyledFlexGap5>
              </Button>
            </Box>
            <StyledFlex
              style={{
                gap: '16px',
                alignItems: 'flex-start',
              }}
            >
              {apiGetResponse &&
                apiGetResponse.result.resultImages.length > 0 &&
                apiGetResponse.result.resultImages.map((image, index) => {
                  return (
                    <StyledImageSetThumbnailBox
                      key={`upload-set-image-${index}`.toString()}
                      style={{
                        outline:
                          selectedNumber === index
                            ? `2px solid ${'var(--color-primary-main)'}`
                            : '1px solid #ccc',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                      }}
                      onClick={() => {
                        // biome-ignore lint/complexity/noVoid: あとで修正
                        void handleSelectRefImage(index, image.url);
                      }}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          bottom: 3,
                          right: 3,
                          zIndex: 10,
                        }}
                      >
                        {selectedNumber === index ? (
                          <CheckedCircle />
                        ) : (
                          <UnCheckedCircle />
                        )}
                      </Box>
                      <StyledImageSetThumbnailCenter>
                        <ImageSpinner
                          src={`${image.url}&d=232x232`}
                          mode="beater"
                          hasBackground={false}
                        >
                          <CombinedSilhouetteImage
                            set={set}
                            image={image}
                            index={index}
                          />
                        </ImageSpinner>
                      </StyledImageSetThumbnailCenter>
                    </StyledImageSetThumbnailBox>
                  );
                })}
            </StyledFlex>
          </Box>
        </Box>
      </>
    );
  },
);
