import { Box } from '@/components/atoms/Box';
import { StyledFlexEnd, StyledGridEnd } from '@/components/styled';
import { BATCH_LIMIT_SET } from '@/constants';
import { StepExplain } from '@/features/components/atoms/StepExplain';
import { StyledExplainBox } from '@/features/components/atoms/StepExplain/styled';
import { BatchUploadingDialog } from '@/features/components/modals/BatchUploadingDialog';
import { ConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog';
import { useConfirmEraseData } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseData';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesBatchStepLayout } from '@/features/components/templates/Layout/FeaturesBatchStepLayout';
import { useDialog } from '@/hooks/global/useDialog';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { Divider } from '@mui/material';
import { memo, useState } from 'react';
import { AddSetButton } from './AddSetButton';
import { Buttons } from './Buttons';
import { Functions } from './Functions';
import { NoticeLimitSet } from './NoticeLimitSet';
import { SetTable } from './SetTable';
import { UploadRule } from './UploadRule';
import { useBatchUpload } from './hooks/useBatchUpload';
import { useBatchUploadWarnings } from './hooks/useBatchUploadWarnings';
import { FunctionViewType } from './types';

/**
 * @see バッチアップロードのメインコンポーネント
 */
export const BatchUpload = memo((): JSX.Element => {
  const { warningsData, warningFunction } = useBatchUploadWarnings();
  const {
    featureData,
    currentNumber,
    isBatchLimitReached,
    batchConstant,
  } = useBatchUpload();
  const [functionViewType, setFunctionViewType] =
    useState<FunctionViewType>('');
  const { isOpenDialog } = useDialog();
  const { uploadingProgress } = useUploadingProgress();

  const {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    handleSubmitEraseDialog,
  } = useConfirmEraseData();

  return (
    <>
      <FeaturesBatchStepLayout>
        <StyledExplainBox>
          <>
            <StepExplain />
            <StyledFlexEnd>
              {batchConstant?.addableSet &&
                <AddSetButton
                  resetWarnings={warningFunction.resetWarnings}
                  isBatchLimitReached={isBatchLimitReached}
                />
              }
            </StyledFlexEnd>
          </>
        </StyledExplainBox>
        <Box>
          {isBatchLimitReached && (
            <NoticeLimitSet BATCH_LIMIT_SET={BATCH_LIMIT_SET} />
          )}
          <SetTable
            setFunctionViewType={setFunctionViewType}
            warningFunction={warningFunction}
          />
          <Divider />
          <Box style={{ display: 'grid', gap: '32px', padding: '24px' }}>
            {featureData &&
            featureData.batch.length > 0 &&
            (featureData?.batch[currentNumber]?.originalImages.length > 0 ||
              featureData?.batch[currentNumber].refSam.combinedBase64) ? (
              ''
            ) : (
              <UploadRule />
            )}
          </Box>
        </Box>
        <StyledFunctionBox sx={{ height: '100%' }}>
          <Functions
            functionViewType={functionViewType}
            warningsData={warningsData}
          />
          <StyledGridEnd>
            <Buttons />
          </StyledGridEnd>
        </StyledFunctionBox>
      </FeaturesBatchStepLayout>
      <BatchUploadingDialog
        isOpen={isOpenDialog}
        loadingProgressPercent={uploadingProgress}
      />
      <ConfirmEraseDataDialog
        isOpenDialog={isOpenConfirmEraseDataDialog}
        handleCloseDialog={handleCloseConfirmEraseDataDialog}
        handleSubmit={handleSubmitEraseDialog}
      />
    </>
  );
});
