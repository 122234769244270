import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fstyled%2Fbox.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xejBzd2JjMCB7CiAgd2hpdGUtc3BhY2U6IG5vd3JhcDsKfQouXzF6MHN3YmMxIHsKICBwYWRkaW5nLXRvcDogMTBweDsKfQouXzF6MHN3YmMyIHsKICBwYWRkaW5nLXRvcDogMTAwcHg7Cn0KLl8xejBzd2JjMyB7CiAgbWFyZ2luLXRvcDogLTMwcHg7Cn0KLl8xejBzd2JjNCB7CiAgd2lkdGg6IDNweDsKfQouXzF6MHN3YmM1IHsKICB3aWR0aDogOHB4Owp9Ci5fMXowc3diYzYgewogIHdpZHRoOiAxMHB4Owp9Ci5fMXowc3diYzcgewogIHdpZHRoOiAxMnB4Owp9Ci5fMXowc3diYzggewogIHdpZHRoOiAyMHB4Owp9Ci5fMXowc3diYzkgewogIHdpZHRoOiAzMHB4Owp9Ci5fMXowc3diY2EgewogIHdpZHRoOiA1MHB4Owp9Ci5fMXowc3diY2IgewogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMTAwJTsKfQouXzF6MHN3YmNjIHsKICB3aWR0aDogMTAwJTsKfQouXzF6MHN3YmNkIHsKICBoZWlnaHQ6IDVweDsKfQouXzF6MHN3YmNlIHsKICBoZWlnaHQ6IDEwcHg7Cn0KLl8xejBzd2JjZiB7CiAgaGVpZ2h0OiAxNXB4Owp9Ci5fMXowc3diY2cgewogIGhlaWdodDogMTZweDsKfQouXzF6MHN3YmNoIHsKICBoZWlnaHQ6IDIwcHg7Cn0KLl8xejBzd2JjaSB7CiAgaGVpZ2h0OiAyNHB4Owp9Ci5fMXowc3diY2ogewogIGhlaWdodDogMjVweDsKfQouXzF6MHN3YmNrIHsKICBoZWlnaHQ6IDMwcHg7Cn0KLl8xejBzd2JjbCB7CiAgaGVpZ2h0OiAzMnB4Owp9Ci5fMXowc3diY20gewogIGhlaWdodDogNjRweDsKfQouXzF6MHN3YmNuIHsKICBoZWlnaHQ6IDEwMHZoOwp9Ci5fMXowc3diY28gewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouXzF6MHN3YmNwIHsKICB0ZXh0LWFsaWduOiBsZWZ0Owp9Ci5fMXowc3diY3EgewogIHBhZGRpbmctbGVmdDogMjBweDsKfQ%3D%3D%22%7D"
export var styledBoxExpanded = '_1z0swbcb';
export var styledBoxHeight10 = '_1z0swbce';
export var styledBoxHeight100vh = '_1z0swbcn';
export var styledBoxHeight15 = '_1z0swbcf';
export var styledBoxHeight16 = '_1z0swbcg';
export var styledBoxHeight20 = '_1z0swbch';
export var styledBoxHeight24 = '_1z0swbci';
export var styledBoxHeight25 = '_1z0swbcj';
export var styledBoxHeight30 = '_1z0swbck';
export var styledBoxHeight32 = '_1z0swbcl';
export var styledBoxHeight5 = '_1z0swbcd';
export var styledBoxHeight64 = '_1z0swbcm';
export var styledBoxMarginTopMinus30 = '_1z0swbc3';
export var styledBoxNowrap = '_1z0swbc0';
export var styledBoxPaddingLeft20 = '_1z0swbcq';
export var styledBoxPaddingTop10 = '_1z0swbc1';
export var styledBoxPaddingTop100 = '_1z0swbc2';
export var styledBoxRelativeWidthExpanded = '_1z0swbcc';
export var styledBoxTextAlignCenter = '_1z0swbco';
export var styledBoxTextAlignLeft = '_1z0swbcp';
export var styledBoxWidth10 = '_1z0swbc6';
export var styledBoxWidth12 = '_1z0swbc7';
export var styledBoxWidth20 = '_1z0swbc8';
export var styledBoxWidth3 = '_1z0swbc4';
export var styledBoxWidth30 = '_1z0swbc9';
export var styledBoxWidth50 = '_1z0swbca';
export var styledBoxWidth8 = '_1z0swbc5';