import {
  FeatureBatchOriginalImage,
  FeatureData,
} from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { memo, useMemo } from 'react';
import {
  StyledImageSetThumbnailBox,
  StyledImageSetThumbnailCenter,
} from '../styled';

type Props = {
  image: FeatureBatchOriginalImage;
  index: number;
  featureData: FeatureData;
  targetNumber: number;
};

export const Thumbnail = memo(
  ({ image, index, featureData, targetNumber }: Props): JSX.Element => {
    const combinedBase64Thumbnail120 = useMemo(
      () => image.mainImage.combinedBase64Thumbnail120,
      [image.mainImage.combinedBase64Thumbnail120],
    );

    return (
      <>
        <StyledImageSetThumbnailBox
          key={`upload-set-image-${index}`.toString()}
          style={{
            outline: image.isSelected
              ? `1px solid ${'var(--color-primary-main)'}`
              : '1px solid #ccc',
            cursor: 'pointer',
            backgroundColor: '#fff',
          }}
        >
          <StyledImageSetThumbnailCenter>
            <ImageSpinner
              src={`${image.url}&d=118x118`}
              mode="beater"
              hasBackground={false}
            >
              <img
                src={image.url}
                alt={`画像 ${index + 1}`}
                width={100}
                height={120}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            </ImageSpinner>
          </StyledImageSetThumbnailCenter>
        </StyledImageSetThumbnailBox>
      </>
    );
  },
);
