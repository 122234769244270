import { Box } from '@/components/atoms/Box';
import { CheckedCircle, UnCheckedCircle } from '@/components/icons';
import { StyledFlex } from '@/components/styled';
import { ImageResult } from '@/contexts/FeaturesContext/types';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { useSwiperState } from '@/features/components/organisms/GlobalViewer/hooks/useSwiperState';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useGetResizedSize } from '@/hooks/utils/useGetResizedSize';
import { memo, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  StyledImage,
  StyledThumbnail,
  StyledThumbnailBox,
  StyledThumbnailContainer,
} from './styled';

type Props = {
  imageResults?: ImageResult[];
  label?: React.ReactNode;
  hasCheckIcon?: boolean;
  thumbnailBoxWidth?: number;
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  swiperRef: any;
  // biome-ignore lint/suspicious/noExplicitAny: あとで修正
  setSwiperRef: (swiper: any) => void;
  swiperIndex: number;
  setSwiperIndex: (index: number) => void;
  repeatBackgroundImage?: string;
};

export const SwiperThumbs = memo(
  ({
    imageResults,
    label,
    hasCheckIcon = false,
    thumbnailBoxWidth = 420,
    swiperRef,
    setSwiperRef,
    swiperIndex,
    setSwiperIndex,
    repeatBackgroundImage,
  }: Props) => {
    const thumbnailBoxRef = useRef<HTMLDivElement>(null);
    const { handleChangeSlide } = useSwiperState({
      swiperRef,
      setSwiperRef,
      swiperIndex,
      setSwiperIndex,
    });

    const { imageWidth, imageHeight } = useGetResizedSize({
      imageUrl: imageResults?.[0]?.url,
      maxWidth: 72,
      maxHeight: 72,
    });

    const { checkIndex, findChecked, findDownloaded } = useCheckArray();

    return (
      <Box
        style={{
          position: 'relative',
          height: 520,
          overflow: 'auto',
          overflowX: 'hidden',
          paddingTop: '2px',
        }}
      >
        <StyledFlex>
          <StyledThumbnailContainer
            ref={thumbnailBoxRef}
            width={thumbnailBoxWidth}
          >
            {label && <>{label}</>}
            <StyledThumbnailBox style={{ width: 420 }}>
              {imageResults &&
                imageResults.length > 0 &&
                imageResults.map((imageResult: ImageResult, i: number) => (
                  <StyledThumbnail
                    key={`thumb-${imageResult.url}`.toString()}
                    index={i}
                    swiperIndex={swiperIndex}
                    hasCheckIcon={hasCheckIcon}
                    isChecked={findChecked(i) || false}
                    onClick={() => {
                      handleChangeSlide(i);
                    }}
                  >
                    {hasCheckIcon && (
                      <Box
                        style={{
                          position: 'absolute',
                          zIndex: 11,
                          marginLeft: 47,
                          marginTop: 47,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          checkIndex(i);
                        }}
                      >
                        {findChecked(i) ? (
                          <CheckedCircle />
                        ) : (
                          <UnCheckedCircle />
                        )}
                      </Box>
                    )}
                    {findDownloaded(i) && (
                      <Box
                        style={{
                          position: 'absolute',
                          zIndex: 10,
                          marginLeft: -60,
                          marginTop: -60,
                        }}
                      >
                        <Box
                          style={{
                            width: 8,
                            height: 8,
                            backgroundColor: '#545454',
                            border: '1px solid #fff',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    )}
                    {imageResult.url && (
                      <ImageSpinner
                        width={18}
                        height={18}
                        padding={0}
                        hasBackground={false}
                        src={`${imageResult.url}&d=62x72`}
                      >
                        <StyledImage
                          src={`${imageResult.url}&d=62x72`}
                          alt={`pict${i}`}
                          width={imageWidth}
                          height={imageHeight}
                          style={{
                            objectFit: 'contain',
                            backgroundImage:
                              repeatBackgroundImage && imageWidth && imageHeight
                                ? `url(${repeatBackgroundImage})`
                                : '',
                          }}
                        />
                      </ImageSpinner>
                    )}
                  </StyledThumbnail>
                ))}
            </StyledThumbnailBox>
          </StyledThumbnailContainer>
        </StyledFlex>
      </Box>
    );
  },
);
