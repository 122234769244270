/* eslint-disable no-promise-executor-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useBackendApi } from '@/api/hooks/useBackendApi';
import { Image, ImageSchema } from '@/api/schemas';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useBatchUploadRetryDialog } from '@/features/components/modals/BatchUploadRetryDialog/hooks/useBatchUploadRetryDialog';
import { useBatchConstant } from '@/features/components/steps/hooks/useBatchConstant';
import { useError } from '@/hooks/global/useError';
import { useUploadingProgress } from '@/hooks/global/useUploadingProgress';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { isAxiosTimeoutError } from '@/utils/axios';
import { AxiosError } from 'axios';
import md5 from 'crypto-js/md5';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { z } from 'zod';
import { FileResult, PostFile } from './types';

function calculateMD5(param: string) {
  return md5(param).toString()
}

type Props = {
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
  setApiResponse: Dispatch<SetStateAction<FileResult[]>>;
};

const UPLOAD_BATCH_SIZE = 5

/**
 * JSDoc
 * @see base64画像をpostして画像URLを取得するカスタムフック
 */
export const usePostImages = ({
  handleOpenDialog,
  handleCloseDialog,
  setApiResponse,
}: Props) => {
  const { activeFeatureName } = useFeaturesContext({});
  const isMultiWhite = useMemo(() => {
    return activeFeatureName === 'multiWhite';
  }, [activeFeatureName])

  const { logger } = useError();
  const { postImages, postSegmentationBackground } = useBackendApi({});
  const { batchConstant } = useBatchConstant();
  const { getImageMasking } = useImageMasking();
  const { setUploadingProgress } = useUploadingProgress();
  const { getResizedBase64 } = useResizeBase64();
  const { postTasksV2 } = useBackendApi({});
  const { handleOpenBatchUploadRetryDialog } = useBatchUploadRetryDialog();

  const {
    activateTargetFeature,
  } = useFeaturesContext({});

  const { getImages } = useBackendApi({});

  const initializeStatus = useCallback(() => {
    handleCloseDialog();
    setUploadingProgress(0);
    // setIsRetryMode(false);
  }, [
    handleCloseDialog,
    setUploadingProgress,
    // setIsRetryMode,
  ]);

  // ローカルで選択された画像のアップロード
  const uploadImage = useCallback(async (file: PostFile) => {
    try {
      // ハッシュ値確認のリクエストを行い、結果によってアップロードを行うかどうか決める
      const mainRes: Image | undefined = await postImages({
        image: file.base64,
        fileName: file.fileName,
      });

      return mainRes
    } catch (error) {
      await new Promise((resolve, reject) => {
        reject(error)
      })

      return undefined
    }
  }, [postImages]);

  // 選択された画像がサーバーにアップロード済みかどうかをチェックする
  const checkImageForRetry = useCallback(async (file: PostFile) => {
    try {
      // ハッシュ値確認のリクエストを行い、結果によってアップロードを行うかどうか決める
      const hash = calculateMD5(file.base64)
      const getImagesResult = await getImages({hash})

      return typeof getImagesResult?.data === 'object' && getImagesResult.data.length >= 1 ? getImagesResult.data[0] : null
    } catch (error) {
      await new Promise((resolve, reject) => {
        reject(error)
      })

      return undefined
    }
  }, [getImages]);

  // 画像のセグメンテーション・リサイズなど諸々の処理
  // TODO: この中でやっていることが大きすぎるので必要に応じて分解する
  const processImage = useCallback(async (file: PostFile,  mainRes: z.infer<typeof ImageSchema> | undefined) => {
    const fileResult: FileResult = {
      main: null,
      mask: null,
      url: null,
      base64: null,
      maskImageBase64: null,
      combinedImageBase64: null,
      combinedImageBase64Thumbnail120: null,
      combinedImageBase64Thumbnail268: null,
      combinedImageBase64Thumbnail512: null,
      originalSize: {
        width: file.width,
        height: file.height,
        size: file.size,
      },
    };

    try {
      // ハッシュ値確認のリクエストを行い、結果によってアップロードを行うかどうか決める
      if (mainRes) {
        fileResult.main = mainRes;
        fileResult.base64 = file.base64 || null;
        fileResult.url = file.url || null;
      }

      if (batchConstant && batchConstant.hasMainImageSam) {
        const backgroundRes = await postSegmentationBackground(file.base64);

        if (backgroundRes) {
          fileResult.maskImageBase64 = backgroundRes.maskImageBase64;

          const maskRes = await postImages({
            image: file.base64,
            fileName: `${file.fileName}-mask`,
          });
          if (maskRes) {
            fileResult.mask = maskRes;
          }
          const combinedBase64 = await getImageMasking({
            mainImageSource: file.base64,
            maskImageSource: backgroundRes.maskImageBase64,
          });
          if (file.base64) {
            fileResult.combinedImageBase64 =
              backgroundRes?.maskOverlayImageBase64 || combinedBase64;
            const [resized120, resized268, resized512] =
              await Promise.all([
                getResizedBase64({
                  targetSize: 120,
                  width: file.width,
                  height: file.height,
                  originalBase64: combinedBase64,
                }),
                getResizedBase64({
                  targetSize: 268,
                  width: file.width,
                  height: file.height,
                  originalBase64: combinedBase64,
                }),
                getResizedBase64({
                  targetSize: 512,
                  width: file.width,
                  height: file.height,
                  originalBase64: combinedBase64,
                }),
              ]);
            fileResult.combinedImageBase64Thumbnail120 =
              resized120.base64 || '';
            fileResult.combinedImageBase64Thumbnail268 =
              resized268.base64 || '';
            fileResult.combinedImageBase64Thumbnail512 =
              resized512.base64 || '';
          }
        }
      }

      return fileResult
    } catch (error: unknown) {
      await new Promise((resolve, reject) => {
        reject(error)
      })

      return undefined
    }
  }, [batchConstant, getResizedBase64, getImageMasking, postImages, postSegmentationBackground]);

  // 複数画像に対してprocessImagesを行う。
  const uploadImages = useCallback(async ({
    postFiles,
  }: {
    postFiles: PostFile[]
  }) => {
    const uploadImageIds: string[] = [];

    // MEMO: 少しでも処理を早くするため、画像のアップロードリクエストを同時に{UPLOAD_BATCH_SIZE}枚まで投げるようにしている
    for (let i = 0; i < postFiles.length; i += UPLOAD_BATCH_SIZE) {
      const promises = []

      for(let j = 0; i + j < postFiles.length && j < UPLOAD_BATCH_SIZE; j += 1) {
        const checkResult = await checkImageForRetry(postFiles[i+j])
        if (checkResult) {
          promises.push(checkResult)
        } else {
          promises.push(uploadImage(postFiles[i + j]))
        }
      }
      await Promise.all(promises).then(results => {
        results.forEach(result => {
          if (result !== undefined) uploadImageIds.push(result.id)
        })
      }).catch(error => {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      })

      // TODO: 進捗を動かすのはuploadImagesの責務ではないので外に切り出す
      if (i + UPLOAD_BATCH_SIZE >= postFiles.length) {
        setUploadingProgress(100);
      } else {
        setUploadingProgress(i + UPLOAD_BATCH_SIZE / postFiles.length * 100);
      }
    }

    return uploadImageIds
  }, [
    checkImageForRetry,
    uploadImage,
    setUploadingProgress
  ]);

  // 複数画像に対してprocessImagesを行う。uploadImages
  const uploadAndProceeImages = useCallback((postFiles: PostFile[]) => {
    const processResult = (async () => {
      const segmentationUploadResults: FileResult[] = [];

      // MEMO: 少しでも処理を早くするため、画像のアップロードリクエストを同時に{UPLOAD_BATCH_SIZE}枚まで投げるようにしている
      for (let i = 0; i < postFiles.length; i += UPLOAD_BATCH_SIZE) {
        const promises = []

        for(let j = 0; i + j < postFiles.length && j < UPLOAD_BATCH_SIZE; j += 1) {
          promises.push(uploadImage(postFiles[i + j]))
        }

        const uploadImageResults = await Promise.all(promises)

        for(let j = 0; i + j < postFiles.length && j < UPLOAD_BATCH_SIZE; j += 1) {
          // TODO: エラーハンドリングを整備して（segmentationUploadで返される値に例外的な値を含めないようにして）ネストを減らす
          if (uploadImageResults[j] !== undefined) {
            const fileResult = await processImage(postFiles[i + j], uploadImageResults[j])
            if (fileResult !== undefined) {
              segmentationUploadResults.push(fileResult);
            }
          }
          // TODO: 進捗を動かすのはuploadImagesの責務ではないので外に切り出す
          if (i + j + 1 >= postFiles.length) {
            setUploadingProgress(100);
          } else {
            setUploadingProgress((i + j + 1) / postFiles.length * 100);
          }
        }
      }

      const filteredResults = segmentationUploadResults.filter(
        (result): result is FileResult =>
          result !== null && result !== undefined,
      );

      return filteredResults
    })();

    return processResult
  }, [
    processImage,
    setUploadingProgress,
    uploadImage,
  ]);

  // ローカルの選択された画像を処理する一連の流れ
  const handlePostImages = useCallback((postFiles: PostFile[]) => {
    if (postFiles.length <= 0) return;

    handleOpenDialog();

    (async () => {
      // MEMO: featureによって挙動が異なる。必要があればそれぞれ別のカスタムフックに分ける
      if (isMultiWhite) {
        try {
          const uploadImageIds = await uploadImages({
            postFiles,
          });

          await postTasksV2({
            generationMode: "misc_background_removal_batch",
            originalImages: uploadImageIds.map((id) => ({
              inputImageId: id,
            })),
            parameters: {
              noBackground: false,
              autoMask: true,
            }
          });
          initializeStatus();

          // ギャラリー画面に遷移
          activateTargetFeature('gallery');
        } catch (error) {
          await new Promise((resolve, reject) => {
            reject(error)
          })
        }
      } else {
        const filteredResults = await uploadAndProceeImages(postFiles);
        initializeStatus();
        setApiResponse(filteredResults);
      }
    })().catch((error: AxiosError) => {
      // MEMO: isMultiWhiteのみリトライ画面が存在するため分岐させる
      if (isMultiWhite && isAxiosTimeoutError(error)) {
        handleCloseDialog();
        setUploadingProgress(0);
        handleOpenBatchUploadRetryDialog();
        // setIsRetryMode(false);
      } else {
        logger({ error });
        initializeStatus();
      }
    });
  }, [
    setApiResponse,
    setUploadingProgress,
    // isRetryMode,
    activateTargetFeature,
    handleOpenDialog,
    handleCloseDialog,
    isMultiWhite,
    handleOpenBatchUploadRetryDialog,
    logger,
    initializeStatus,
    postTasksV2,
    uploadImages,
    // setIsRetryMode,
    uploadAndProceeImages,
  ]);

  return {
    handlePostImages,
  }
};
