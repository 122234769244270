import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import {
  StyledBoxNowrap,
  StyledFlexGap15,
  StyledFont,
  StyledFontBold,
} from '@/components/styled';
import { TASK_STATUS } from '@/constants';
import {
  StyledImage,
  StyledImageThumbnail,
} from '@/features/Gallery/uniques/styled';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { StyledDownloadDot } from '@/features/components/styled/icon';
import { useTasksHelpers } from '@/hooks/tasks/useTasksHelpers';
import { memo } from 'react';

type Props = {
  data: Task;
};

export const Completed = memo(({ data }: Props): JSX.Element => {
  const { isDownloaded } = useTasksHelpers();

  return (
    <StyledFlexGap15>
      <Box>
        {data.result.resultImages[0] && data.result.resultImages[0].url && (
          <StyledImageThumbnail>
            <ImageSpinner
              width={18}
              height={18}
              padding={0}
              hasBackground={false}
              src={`${data.result.resultImages[0].url}&d=56x56`}
              mode="beater"
            >
              <StyledImage
                src={`${data.result.resultImages[0].url}&d=56x56`}
                width={56}
                height={56}
                alt={`${data.result.resultImages[0].url}`}
                style={{
                  objectFit: 'contain',
                  width: 56,
                  height: 56,
                  backgroundImage:
                    data.parameters?.noBackground && houndstooth
                      ? `url(${houndstooth.src})`
                      : '',
                }}
              />
            </ImageSpinner>
          </StyledImageThumbnail>
        )}
        {!data.result.resultImages[0] && (
          <StyledImageThumbnail>no image</StyledImageThumbnail>
        )}
      </Box>
      <StyledBoxNowrap>
        {isDownloaded(data) ? (
          <StyledFont>
            {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
          </StyledFont>
        ) : (
          <StyledFontBold>
            {TASK_STATUS[data.result.status as keyof typeof TASK_STATUS]}
          </StyledFontBold>
        )}
      </StyledBoxNowrap>
      {/* resultImagesからダウンロードされているかを調べる */}
      <div style={{width: '7px'}}>
        {!isDownloaded(data) && <StyledDownloadDot />}
      </div>
    </StyledFlexGap15>
  );
});
